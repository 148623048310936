
export function loadLang(lang) {
    return localeObj[lang]
}

const localeObj = {


    
    'cn': {
 
        tutorials1: '下载问题',
        tutorials2: '连接问题',
        tutorials3: '赚取佣金',
        tutorials4: '其它问题',
        tutorials5: 'QUESTIONS',
        tutorials6: 'When the title exceeds the length limit, it will be displayed on a new line?',
        tutorials7: 'question title',
        tutorials8: '',
        tutorials9: '',
        tutorials10: '',
        tutorials11: '',
        tutorials12: '',
        tutorials13: '',
        tutorials14: '',
        tutorials15: '',
        tutorials16: '',
        tutorials17: '',
        tutorials18: '',
        tutorials19: '',
        tutorials20: '',
        tutorials21: '',
        tutorials22: '',
        tutorials23: '',
        tutorials24: '',
        tutorials25: '',
        tutorials26: '',
        tutorials27: '',
        tutorials28: '',
        tutorials29: '',

       /* 
        home10: '',
        home11: '',
        home12: '',
        home13: '',
        home14: '',
        home15: '',
        home16: '',
        home17: '',
        home18: '',
        home19: '',
        home20: '',
        home21: '',
        home22: '',
        home23: '',
        home24: '',
        home25: '',
        home26: '',
        home27: '',
        home28: '',
        home29: '', */
        
        home10: '全网加速 不限速 不限设备数',
        home11: '海量专线带宽，高性能服务器，支持全端加速，一个账号，全端互通，满足游戏、影音、直播、办公等多种需求。一键加速，极简操作，极致体验。.',
        home12: '(免费试用)',
        home13: '更多客户端',
        home14: '全网唯一',
        home15: '不限设备数',
        home16: '的加速器',
        home17: '多设备同时登录，办公娱乐更便捷',
        home18: 'Windows',
        home19: 'Android',
        home20: 'iOS',
        home21: 'macOS',
        home22: '',
        home23: '',
        home24: '尽情畅享更私密、更安全的互联网，阻止网络跟踪器',
        home25: '为什么选择绝尘加速器',
        home26: '极速体验',
        home27: '真正的不限速，致力带给您 如同本地网路般的上网体验',
        home28: '不限设备数',
        home29: '不限设备数的加速器，多设备同时在线，工作娱乐更便捷',
        home30: '超低延迟',
        home31: '海量专线带宽，致力提供接近极限的低延迟',
        home32: '日套餐 随用随开',
        home33: '提供日套餐，什么时候用就什么时候开，永不担心被套牢。',
        home34: '行业领先的加密技术',
        home35: '通过 256 位 AES 加密、DNS/IPv6泄漏保护和终止开关来保护您的数据和在线活动。',
        home36: '卓越的客服团队',
        home37: '365天都有专业的人工客服提供技术支持，保障您的售前售后权益。',
        home38: '',
        home39: '', 
        home40: '覆盖40+国家，极速畅享全球互联网，服务器不断扩充中！', 
 
        
 
        footer10: '绝尘致力于带给您如同本地网络般的体验, ',
        footer11: '绝尘加速器',
        footer12: '关于我们',
        footer13: '隐私政策',
        footer14: '服务条款',
        footer15: '我们的服务',
        footer16: '人工客服',
        footer17: '使用帮助',
        footer18: 'IP 查询',
        footer19: '商业合作',
        footer20: '我们的邮箱',
        footer21: 'juechenvpn@gmail.com',
        footer22: '绝尘加速器. All rights reserved',
        footer23: '您好！很高兴为您服务',
        footer24: '回到顶部', 
        footer25: '更多定制服务正在研发中，敬请期待',
        footer26: '客服时间：12:00-23:59',
 
 
        windows10: '绝尘帮助中心',
        windows11: 'Windows下载失败？',
        windows12: '原因：因该类软件的行业特殊性，下载时可能会遇到各种阻碍。',
        windows13: '如下载遇到问题，请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
        windows14: '问题类型：',
        windows15: '浏览器拦截',
        windows16: '安装包/压缩包被删',
        windows17: '软件被删',
        windows18: '第 1 步：点击 <span class="bold">“保留”</span> 后 <span class="bold">“显示详细信息</span>”<br>',
        windows19: '第 2 步：点击 <span class="bold">”仍然保留”</span><br>', 
        windows20: '第 1 步：打开电脑 <span class="bold">设置 --> 更新与安全 --> Windows安全中心 --> 病毒和威胁防护 --></span>  请看第2步<br>',
        windows21: '第 2 步：进入 <span class="bold">病毒和威胁防护 --> 管理设置</span><br>', 
        windows22: '第 3 步：进入 <span class="bold">“病毒和威胁防护”设置 --> 关闭实时保护 --></span> 如果不想关闭，请参考第四步<br>',
        windows23: '第 4 步：同样是在 <span class="bold">“病毒和威胁防护”设置</span> 页--> 滚动鼠标下滑找到 <span class="bold">排除项</span> --> 点击<span class="bold">“添加或删除排除项”</span>--> 点击弹窗中的<span class="bold">“是“</span>  <br>', 
        windows24: '第 5 步：点击 <span class="bold">“添加排除项”设置</span> 页--> <span class="bold">文件夹</span> --> 找到绝尘加速器的文件夹  --> 点击该文件夹  --> 在右下角点击<span class="bold">“选择文件夹”</span> --> 这样就完成了，可以使用加速器了。  <br>', 
        windows25: '第 1 步：打开电脑 <span class="bold">设置 --> 更新与安全 --> Windows安全中心 --> 病毒和威胁防护 --></span> 请看第2步   <br>', 
        windows26: '第 2 步：进入 <span class="bold">病毒和威胁防护 --> 保护历史记录</span>，在检查出威胁栏目里，找到“绝尘加速器”，选择恢复即可。（由于不同的系统版本，设置方法略有差异，如需帮助请点击右侧客服图标）  <br>', 

      
        android10: '绝尘帮助中心',
        android11: '安卓端安装失败？',
        android12: '原因：因该类软件的行业特殊性，下载/安装时可能会遇到各种阻碍。',
        android13: '如下载遇到问题，请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
        android14: '手机品牌：',
        android15: '华为/荣耀',
        android16: '小米/红米',
        android17: 'VIVO',
        android18: 'OPPO',
        android19: '一加',
        android20: '第 1 步：打开手机 <span class="bold">设置 --> 安全</span> --> 请看第2步 <br>',
        android21: '第 2 步：打开 <span class="bold">更多安全设置 --> 安装外部来源应用</span> --> 找到当前使用的浏览器给予相关安装权限（如uc浏览器） <br>',
        android22: '第 1 步：打开手机 <span class="bold">设置 --> 隐私保护</span> --> 请看第2步 <br>',
        android23: '第 2 步：点击“<span class="bold">管理</span>” <br>',
        android24: '第 3 步：点击“<span class="bold">特殊应用权限</span>” <br>',
        android25: '第 4 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android26: '第 5 步：找到当前使用的浏览器给予相关安装权限（如Google浏览器） <br>',
        android27: '第 1 步：打开手机 <span class="bold">设置 --> 应用与权限</span> --> 请看第2步 <br>',
        android28: '第 2 步：点击“<span class="bold">权限</span>” <br>',
        android29: '第 3 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android30: '第 4 步：找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',
        android31: '第 1 步：打开手机 <span class="bold">设置 --> 安全</span> --> 请看第2步 <br>',
        android32: '第 2 步：点击“<span class="bold">安装外部来源应用</span>” <br>',
        android33: '第 3 步：点击“<span class="bold">安装外部来源应用</span>”找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',
        android34: '第 1 步：打开手机 <span class="bold">设置 --> 应用和通知</span> <br>',
        android35: '第 2 步：点击“<span class="bold">特殊应用权限</span>” <br>',
        android36: '第 3 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android37: '第 4 步：找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',


       /*   <span class="bold"></span>  */
       ios10: '绝尘帮助中心',
       ios11: 'iOS/Mac下载教程',
       ios12: '原因：因该类软件的行业特殊性，大陆暂时不支持上架。',
       ios13: '下载请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
       ios14: '请选择符合您的情形',
       ios15: '我有境外的苹果ID',
       ios16: '我只有大陆的苹果ID',
       ios17: '第 1 步：打开手机 <span class="bold">App Store</span> <br>',
       ios18: '第 2 步：如当前登录的是大陆ID，请点击“<span class="bold">头像</span>”icon。如已登录境外ID，请直接看第5步。 <br>',
       ios19: '第 3 步：点击头像后进入“账户” <br>',
       ios20: '第 4 步：滑至底部，点击“<span class="bold">登出</span>”--> 然后登录您的境外ID就好 <br>',
       ios21: '第 5 步：点击按钮跳转商城，或直接在商城搜索“绝尘加速器“或者”JC VPN” <br>',
       // collapse titles 
       ios22: '创建专属的境外苹果ID',
       ios23: '推荐',
       ios24: '自己的专属ID更安全，更方便',
       ios25: '点击展开教程',// down
       ios25up: '点击收起教程',// up
       ios26: '修改你的苹果ID地区',
       ios27: '在已有的苹果ID修改地区即可安装',//
       ios28: '使用共享的境外苹果ID',
       ios29: '谨记不要在设置里登录，请在App Store登录', 
       // 
       ios30: '第 1 步：前往<a href="https://appleid.apple.com/account" target="_blank">苹果官网ID注册页</a> --> 填写“ <span class="bold">姓名、国家或地区、邮箱、密码、手机号码</span>”即可 <br>',
       ios31: '第 2 步：<span class="bold">验证邮箱/手机号 </span>-->验证成功后请看下一步 <br>',
       ios32: '第 3 步：<span class="bold">添加付款方式</span>（从美国地址生成器把内容复制粘贴就行，前往<span class="bold"><a href="https://www.meiguodizhi.com/" target="_blank">美国地址生成器</a></span>） <br>',
       ios33: '第 4 步：打开“App Store”  <br>',
       ios34: '第 5 步：如当前登录的是大陆ID，请点击“<span class="bold">头像</span>”图标（ 如未登录请直接登录刚注册的ID） <br>',
       ios35: '第 6 步：进入“账户”后，滑至底部，点击“<span class="bold">登出</span>”，然后登录刚注册的ID <br>',
       ios36: '第 7 步：登录成功后，搜索“<span class="bold">绝尘加速器”或者“JCVPN”</span>，下载即可畅游全球 <br>',

       ios37: '前往苹果官方教程',
       ios38: '联系客服获取',


       client_windows:"下载客户端",
       client_android:"下载Android端",
       client_ios:"下载iOS端",
       client_mac:"下载Mac端",
       client_linux:"Linux", 

       payment_success1:"充值绝尘币",
       payment_success2:"复制",
       payment_success3:"支付成功!",
       payment_success4:"感谢您的购买！如遇问题，请联系客服~",
       payment_success5:"订单号",
       payment_success6:"已复制订单号", 
       payment_success7:"订单号未复制",

 
        privacy1:  '绝尘加速器（以下简称绝尘）通过在连接到Internet时建立安全的虚拟专用网络，将服务作为保护您隐私的一种手段。<br>',
        privacy2:  '绝尘尊重并保护所有使用绝尘网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务,绝尘会按照本隐私政策的规定使用和披露您的个人信息，但绝尘将以高度的勤勉、审慎义务对待这些信息。<br>',
        privacy3:  '除本隐私权政策另有规定外,在未征得您事先许可的情况下,绝尘不会将这些信息对外披露或向第三方提供。绝尘会不时更新本隐私权政策。您在同意绝尘络服务使用协议之时,即视为您已经同意本隐私权政策全部内容。本隐私权政策属于绝尘网络服务使用协议不可分割的一部分。',
        privacy4:  '1. 用户数据收集',
        privacy5:  '我们确保永远不会记录用户的浏览历史记录，访问的数据内容，以及DNS查询。因此：',
        privacy6:  '我们不知道哪个用户曾经访问过特定的网站或服务。',
        privacy7:  '我们不知道哪个用户在特定时间连接了加速器以及使用了哪个加速器服务器的IP地址。',
        privacy8:  '我们不知道用户计算机或者移动设备的原始IP地址。',
        privacy9:  '如果有人需要我们提供上述用户信息，我们将无法提供，因为数据不存在。',
        privacy10: '手机号和邮箱',
        privacy11: '我们后期在用户注册时，可能会选择性让用户绑定手机号和邮箱（选填），我们收集用户绑定的手机号或者邮箱，用于帮助用户找回账号密码，以及在反馈问题后及时收到我们的回复。',
        privacy12: '设备型号和系统版本',
        privacy13: '我们收集用户的设备型号和系统版本，用来统计哪些型号的设备或者操作系统，连接VPN后不太好用，便于我们针对性的调试和解决问题。',
        privacy14: '2. 信息使用',
        privacy15: 'a) 绝尘不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息,除非事先得到您的许可',
        privacy16: 'b) 绝尘亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何绝尘平台用户如从事上述活动,一经发现,绝尘有权立即终止与该用户的服务协议。',
        privacy17: 'c) 为服务用户的目的,绝尘可能通过使用您的个人信息,向您提供您感兴趣的信息,包括但不限于通过邮件或者短信等向您发出产品和服务信息。',
        privacy18: '3. 信息披露',
        privacy19: '在如下情况下,绝尘将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息:',
        privacy20: 'a) 经您事先同意,向第三方披露;',
        privacy21: 'b) 为提供您所要求的产品和服务,而必须和第三方分享您的个人信息;',
        privacy22: 'c) 根据法律的有关规定,或者行政或司法机构的要求,向第三方或者行政、司法机构披露;',
        privacy23: 'd) 如您出现违反所在国家地区的有关法律、法规或者绝尘服务协议或相关规则的情况,需要向第三方披露;',
        privacy24: 'e) 其它绝尘根据法律、法规或者网站政策认为合适的披露。',
        privacy25: '4. 信息安全',
        privacy26: 'a) 绝尘帐号均有安全保护功能,请妥善保管您的用户名及密码信息。绝尘将通过对用户密码进行加密等安全措施确保您的信息不丢失,不被滥用和变造。尽管有前述安全措施,但同时也请您注意在信息网络上不存在“完善的安全措施”。',
        privacy27: 'b) 如您发现自己的个人信息泄密,尤其是绝尘用户名及密码发生泄露,请您立即联络绝尘客服,以便绝尘采取相应措施。',
        privacy28: '5.改变',
        privacy29: 'a)我们保留自行决定随时修改或替换这些条款的权利。在这些修订生效后继续访问或使用我们的服务，您同意受修订条款的约束。如果您不同意新条款，请停止使用本服务。',
        privacy30: '我们可能随时和不时更改我们的条款。最新版本的条款反映在本协议顶部的版本日期。所有更新和修订在更新后立即生效。我们建议您经常查看我们的条款，以便随时了解可能对您产生的影响，因为您继续使用该申请表示您继续同意接受我们的条款约束。',
        privacy31: 'b)我们可以出于任何原因立即终止或暂停访问我们的服务，恕不另行通知或承担任何责任，包括但不限于您违反本条款。本条款的所有条款本质上应在终止后继续有效，包括但不限于所有权条款，保证免责声明，赔偿和责任限制。',
        privacy32: '6.适用法律',
        privacy33: '这些条款应受所在国家地区的法律管辖和解释，而不考虑其法律冲突规定。',
        privacy34: '我们未能执行这些条款的任何权利或规定将不被视为放弃这些权利。如果这些条款的任何条款被法院认定为无效或不可执行，则这些条款的其余条款将继续有效。这些条款构成我们之间关于我们服务的完整协议，并取代我们之间可能拥有的有关服务的任何先前协议。',

        terms1:  '一般的',
        terms2:  '我们的目标是为所有客户提供优质服务。在您访问本网站或服务之前，请仔细阅读这些服务条款。',
        terms3:  '本服务条款（以下简称“本条款”）约束您对 绝尘加速器 及其相关网站域名（以下简称“网站”）和相关服务（以下简称“服务”）的使用，这些服务由 绝尘加速器 团队（以下简称“绝尘”、“我们的”、“我们”）拥有和运营。本条款构成您与 绝尘 团队之间具有法律约束力的协议（以下简称“协议”）。',
        
        terms4:  '绝尘加速器 服务',
        terms5:  '您了解我们为您提供虚拟专用网络（或“加速器”）服务，包括但不限于使用服务器、传输、路由器、IP 地址和其他设备和协议通过我们的网络（“系统”）传输信息。您同意遵守有关您使用服务的条款。',
        
        terms6:  '使用政策',
        terms7:  '通过使用 绝尘加速器 服务（“服务”），您同意：',
        terms8:  'a) 不得使用该系统发送垃圾邮件、端口扫描、扫描开放代理或开放中继、发送选择加入电子邮件、未经请求的电子邮件或任何类型或版本大量发送的电子邮件，即使该电子邮件最终是从另一台服务器发送的。',
        terms9:  'b) 不要从我们的服务启动任何弹出窗口。',
        terms10: 'c) 在使用我们服务时不得以任何方式攻击任何其他计算机或网络。',
        terms11: 'd) 不得使用该服务进行可能损害我们的网络或其他人的事情。',
        terms12: 'e) 用户有责任了解并理解与您和您的行为有关的任何司法管辖区或地点的所有相关法律。绝尘加速器 不以任何方式或形式对其用户的行为负责，包括刑事责任和对造成或未造成的损害的民事责任，超出具体个人向 绝尘加速器 支付的任何服务费用。',
        terms13: 'f) 违反本使用政策或条款可能会导致您的帐户被终止，并且不会退还之前为服务支付的任何金额。此外，您可能要对 绝尘 遭受的任何和所有损失负责，包括由于上述违规行为而由任何外部实体收取的任何金额，包括但不限于律师费和费用。',
        terms14: 'g) 绝尘加速器 可让您将软件、软件更新或补丁或其他实用程序和工具下载到您的手机或支持互联网的设备上。绝尘加速器（“软件”）授予您非独占的有限许可，仅允许您根据本条款将软件用于 绝尘加速器 在向您提供软件时所述的目的。严禁修改、分发给未经授权的各方、进行逆向工程或以任何未经 绝尘加速器 明确授权的方式使用软件。',
        
        terms15: '免责声明',
        terms16: 'a) 我们努力防止网站和服务中断。但是，这些内容是按“原样”和“可用”提供的，并且我们不以明示或暗示的方式保证通过网站或服务提供的任何材料或信息的准确性，也不保证其适用于任何特定用途。',
        terms17: 'b) 实际服务覆盖范围、速度、位置和质量可能有所不同。除维护和维修的有限时间外，服务将尝试随时可用。但是，服务可能会因我们无法控制的各种因素而不可用，包括紧急情况、第三方服务故障、传输、设备或网络问题或限制、干扰、信号强度，并且可能会中断、拒绝、限制或缩减。',
        terms18: 'c) 我们不保证服务将满足您的要求，也不保证服务不会中断、及时、安全或无错误，也不保证缺陷（如有）将得到纠正。您承认，您访问网站和服务完全出于您的判断并由您自行承担风险。',
        terms19: 'd) 对于因服务或通信服务或网络（例如 T-1 线路或互联网）中断或性能问题而导致的数据、消息或页面丢失、未送达、延迟或误传，我们概不负责。我们可自行决定限制使用量或服务、暂停服务或阻止某些类型的使用，以保护用户或服务。网络速度是估算值，并不表示您或服务发送或接收数据的速度。实际网络速度将根据配置、压缩、网络拥塞和其他因素而有所不同。我们无法保证所接收数据的准确性和及时性；可能会出现延迟或遗漏。',
        terms20: 'e) 我们不记录任何用户活动（访问的网站、DNS 查询、电子邮件等）。我们仅记录对我们服务器的访问尝试，以用于故障排除和改进服务。我们不参与任何形式的审查。我们不会将您的个人信息提供给任何第三方。',
        
        terms21: '条款变更',
        terms22: 'a) 绝尘加速器 可能会不时更新本条款，恕不另行通知。本条款的任何变更都将纳入修订后的协议，我们将在网站上发布该协议。除非另有规定，否则此类变更自发布之日起生效。如果我们对这些条款做出重大变更，我们将通过应用程序或在您查看我们的网站时通知您。',
        
        terms23: '最后',
        terms24: 'a) 绝尘加速器 深信消费者隐私、安全和网络自由，我们以此管理和保护消费者隐私和个人信息。如果您有任何问题或疑问，我们都会通过电子邮件地址<br>juechenvpn@gmail.com为您提供支持',
        



        home: '首页', 
        support:'使用帮助',
        contactus:'在线咨询',
        settings: '设置',
        change_locale: '改变区域',
        dashboard: '控制面板',
        login_username: '登录_用户名',
        login_password: '登录_密码',
        submit: '提交',
        logout: '退出账户', 
        add_new_user: '添加新用户',
        username: '用户名',
        password: '密码', 
        email: '邮箱',
        phone: '电话',
        activate: 'Activate',
        validity: 'Validity',
        active: '活跃的',
        inactive: '不活跃的',
        awaiting: 'Awaiting', 
        traffic_done: 'Traffic Done',
        status: '地位',
        display: 'Display',  
        permission: '权限',
        date_created: '创建日期',
        action: '行动', 
        increase: '增加',
        decrease: 'Decrease',
        add: '添加',
        edit: '编辑',
        delete: '删除',
        go_back: '返回',
        select: '选择',  
        name: "名称",
        key: "钥匙", 
        bandwidth: 'Bandwidth',
        extra_bandwidth: 'Extra Bandwidth',
        bandwidth_high: 'Bandwidth High limit(GB)',
        bandwidth_low: 'Bandwidth Low limit(GB)',
        bhigh: 'High',
        blow: 'Low', 
        confirm_delete: '确定删除', 
        save:'Save',
        billed: '计费',
        not_listed: 'Not Listed',
        sorting_order: '序号',
        description: '描述',
        monthly: '每月的',
        weekly: 'Weekly',
        quarterly: 'Quarterly',
        biannual: 'Biannual',
        yearly: '每年的',
        manually: '手动',
        title: '标题',
        for: "为了",
        all: "全部", 
        android: "Android", 
        ios: "IOS",
        languages: "语言",
        price: '价格',
        coins: 'Coins',
        extra_coins: 'Extra Coins',
        is_basic_plan: 'Basic Plan',
        discount: '折扣',
        rate:'Rate',
        currencies: "货币",
        detail: '明细',
        speed_limit: '限速',
        manage: '管理',
        payment: '支付',
        methods: '方法',
        mode: '模式',
        live: '线上',
        data: '数据',
        icon: '图标',
        code: '代码',
        channels: '渠道',
        groups: '团体',
        group: '团体',
        remark: '备注',
        info: '信息',
        assign: '分配',
        to: '至',
        method: '方法',
        shortcuts: '快捷方式',
        link: '链接',
        view: '浏览',
        list: '列表',
        users: '用户',
        user: '用户',
        search: '搜索',
        servers: '服务器',
        areas: '地区',
        types: '类型', 
        lists: 'Lists',
        information:'Information',
        main: 'Main',
        multiple:'Multiple',
        port: 'Port',
        ports: 'Ports',
        total: '合计',
        clients: '客户端',
        none: 'Not Selected',
        announcements: '公告',
        created: '已创建',
        expired: '已到期',
        at: '在',
        url: '网址',
        yes: '是',
        no: '否',
        base_currency: '基础货币',
        config: '配置', 
        configuration: 'Configuration',
        order: '命令',
        orders: '订单',
        number: '数字',
        amount: '金额',
        package: '套餐',
        paid: '已支付',
        un_paid: '未支付',
        started: '已启动',
        completed: '完成的',
        pending: '待定',
        cancelled: '取消的',
        rejected: '被拒绝的',
        refund: '退款',
        default: '默认',
        allowed: '允许',
        devices: '设备',
        additonal: '附加的',
        version: '版本',
        version_old: '老版本',
        sha256: 'sha256',
        pack_size: '包装大小',
        force: '强制',
        type: '类型',
        force_type_zero: '用户选择更新',
        force_type_one: '强制全部更新l',
        currency: '货币',

        apply: '申请', 
        reset: '重置',
        close: '关',
        plan_time: '计划时间',
        minutes: '分钟', 
        days:"Days",
        packages: '套餐',
        start: '开始',
        end: '结尾',
        date: '日期',
        free: '自由',
        premium: '高级套餐‘',
        both: '双方',
        transfer: '转移',
        id: 'ID',
        url_config: '网址配置',
        value: '价值',
        extra: '额外的',

        online: '在线的',
        today: '今天',
        new: '新的',
        not: '不是',
        confirmed: '确认的',
        trial: '审判',
        reward: '报酬',
        login: '登录',
        level: '等级',
        language: '语',
        invite: '邀请',
        referrer: '推荐人',
        faq: 'FAQ',
        question: '题',
        answer: '回答',

        blog: '博客',
        slug: '蛞蝓',
        viewed: '看过',
        image: '图片',
        meta: '元',
        text: '文本',
        optional: '可选的',
        keywords: '关键词',
        welcome: '欢迎',
        console: '安慰',
        author: '作者',
        category: '类别', 
        categories: 'Categories',
        months: '月',
        recommended: '推荐',
        news: '消息',
        ticket: 'Tickets',
        subject: 'Subject',
        resolved: 'Resolved',
        replied: 'Replied',
        spam: 'Spam',
        reply: 'Reply',
        message: 'Message',
        messages: 'Messages',
        admin:'Admin',
        admin_operation_logs: 'Admin Operation Logs',
        restricted: 'Restricted',
        area: 'Area',
        reports: 'Reports',
        registered: 'Registered',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        last_90_days: 'Last 90 days',
        last_365_days: 'Last 1 year',
        last_2_years_days: 'Last 2 Years',
        last_3_years_days: 'Last 3 Years',
        days_count:' Days Count',
        _1_days: ' 1 Days',
        _3_days: ' 3 Days',
        _7_days: '7 Days',
        _30_days: '30 Days',
        _90_days: '90 Days',
        _180_days: '180 Days',
        _1_years_days: '1 year (360 Days) ',
        _2_years_days: '2 Years (730 Days)',
        _3_years_days: '3 Years (1095 Days)',
        client: 'Client',
        tutorial: 'Tutorial',
        paying: 'Paying',
        other: 'other',
        yesterday: 'Yesterday',
        custom_date_filter: 'Custom date',
        vpn: 'VPN',
        lattitude: 'lattitude',
        longitude: 'longitude',
        max_network_bandwidth: 'Max network bandwidth',
        network_bandwidth: 'Network bandwidth',
        load: 'load',
        mid: 'MID',
        hourly: 'Hourly',
        udid: 'udid',
        token: 'Token',
        first: 'First',
        time: 'Time',
        bugs: 'Bugs',
        refresh: 'Refresh',
        user_id: 'User ID',
        node_id: 'Node',
        log_detail: 'Log Detail',
        device_detail: 'Device Detail',
        client_id: 'Client_ID',
        unique_no: 'Unique No',
        clients_error: 'Errors',
        renewals: 'Renewals',
        download: 'download',
        last_active_time: 'Last active time',
        temp: 'Temp',
        offer: 'offer',
        push_notification: 'Push Notification',
        send: 'Send',
        device: 'Device',
        whitelist: 'Whitelist',
        points: 'Points',
        total_points: 'Total Points',
        deep_link: 'Deep Link',
        is_offer: 'Is Offer',
        is_disabled: 'Is Disabled',
        gift_code_builder: 'Gift Code Builder',
        batch: 'Batch',
        count: 'Count',
        purpose: 'Purpose',
        plan: 'Plan',
        plans: 'Plans',
        generated_by: 'Generated By',
        receive_money: 'Receive Money',
        finance_comment: 'Finance comment',
        gift_codes: 'Gift Codes',
        cancel: 'Cancel',
        generate: 'Generate',
        btach_name: 'Batch Name',
        sr_no: 'SR.NO',
        card_code: 'Card-Code',
        redeem_status: 'Redeem Status',
        used_time: 'Used Time',
        used: 'Used',
        demo: 'Preview', 
        refer_friend:"Refer Friend",
        verify_email: "Verify Email", 
        by: "by",  
        last: "Last", 
        preffered_language: "Preffered language",
        preffered_theme: "Preffered theme",
        guest_user: "Guest user", 
        verification_code: "Verification Code",
        refferal_id: "Refferal ID",
        order_history: "Order history",
        packages_history: "Packages history",
        order_not_find:"No Orders found",
        packages_not_find:"No packages found",
        earn_points_not_found:"No Earn points found",
        redeem_points_not_found: "No Redeem Points found",
        devices_not_found: "Devices not found",
        tickets_not_found: "Tickets not found",
        lookup: "Lookup",
        transaction_id: "Transaction ID",
        check: "Check",
        allowed_countries: "Allowed Countries",
        guest: "Guest",
        redeem: "Redeem",
        account: "Account",
        current: "Current",
        change_password_heading: "Please change your password",
        country: 'Country',  
        domains: 'Domains',
        domain: 'Domain',
        weight: 'Weight', 
        protocol:'Protocol',
        available:"Available",
        
        order_number_not_found:"Order not found with this order number.",
        order_username_different:"Order username not matched with given username.",
        package_not_found:"Package not found.", 
        balance:"Balance", 
        in:"In",
        out:"Out",
        deleted:"Deleted",


    },

    'en': {
 
        
        home10: '全网加速 不限速 不限设备数',
        home11: '海量专线带宽，高性能服务器，支持全端加速，一个账号，全端互通，满足游戏、影音、直播、办公等多种需求。一键加速，极简操作，极致体验。.',
        home12: '(免费试用)',
        home13: '更多客户端',
        home14: '全网唯一',
        home15: '不限设备数',
        home16: '的加速器',
        home17: '多设备同时登录，办公娱乐更便捷',
        home18: 'Windows',
        home19: 'Android',
        home20: 'iOS',
        home21: 'macOS',
        home22: '',
        home23: '',
        home24: '尽情畅享更私密、更安全的互联网，阻止网络跟踪器',
        home25: '为什么选择绝尘加速器',
        home26: '极速体验',
        home27: '真正的不限速，致力带给您 如同本地网路般的上网体验',
        home28: '不限设备数',
        home29: '不限设备数的加速器，多设备同时在线，工作娱乐更便捷',
        home30: '超低延迟',
        home31: '海量专线带宽，致力提供接近极限的低延迟',
        home32: '日套餐 随用随开',
        home33: '提供日套餐，什么时候用就什么时候开，永不担心被套牢。',
        home34: '行业领先的加密技术',
        home35: '通过 256 位 AES 加密、DNS/IPv6泄漏保护和终止开关来保护您的数据和在线活动。',
        home36: '卓越的客服团队',
        home37: '365天都有专业的人工客服提供技术支持，保障您的售前售后权益。',
        home38: '',
        home39: '', 
        home40: '覆盖40+国家，极速畅享全球互联网，服务器不断扩充中！', 
 
        
 
        footer10: '绝尘致力于带给您如同本地网络般的体验, ',
        footer11: '绝尘加速器',
        footer12: '关于我们',
        footer13: '隐私政策',
        footer14: '服务条款',
        footer15: '我们的服务',
        footer16: '人工客服',
        footer17: '使用帮助',
        footer18: 'IP 查询',
        footer19: '商业合作',
        footer20: '我们的邮箱',
        footer21: 'juechenvpn@gmail.com',
        footer22: '绝尘加速器. All rights reserved',
        footer23: '您好！很高兴为您服务',
        footer24: 'Scroll to top', 
        footer25: '更多定制服务正在研发中，敬请期待',
        footer26: '客服时间：12:00-23:59',

 
        
 
        windows10: '绝尘帮助中心',
        windows11: 'Windows下载失败？',
        windows12: '原因：因该类软件的行业特殊性，下载时可能会遇到各种阻碍。',
        windows13: '如下载遇到问题，请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
        windows14: '问题类型：',
        windows15: '浏览器拦截',
        windows16: '安装包/压缩包被删',
        windows17: '软件被删',
        windows18: '第 1 步：点击 “<span class="bold">保留</span>” 后 <span class="bold">“显示详细信息</span>”  <br>',
        windows19: '第 2 步：点击 ”<span class="bold">仍然保留</span>” <br>', 
        windows20: '第 1 步：打开电脑 <span class="bold">设置 --> 更新与安全 --> Windows安全中心 --> 病毒和威胁防护 --></span>  请看第2步  <br>',
        windows21: '第 2 步：进入 <span class="bold">病毒和威胁防护 --> 管理设置</span>  <br>', 
        windows22: '第 3 步：进入 <span class="bold">“病毒和威胁防护”设置 --> 关闭实时保护 --></span> 如果不想关闭，请参考第四步 <br>',
        windows23: '第 4 步：同样是在 <span class="bold">“病毒和威胁防护”设置</span> 页--> 滚动鼠标下滑找到 <span class="bold">排除项</span> --> 点击<span class="bold">“添加或删除排除项”</span>--> 点击弹窗中的<span class="bold">“是“</span>  <br>', 
        windows24: '第 5 步：点击 <span class="bold">“添加排除项”设置</span> 页--> <span class="bold">文件夹</span> --> 找到绝尘加速器的文件夹  --> 点击该文件夹  --> 在右下角点击<span class="bold">“选择文件夹”</span> --> 这样就完成了，可以使用加速器了。  <br>', 
        windows25: '第 1 步：打开电脑 <span class="bold">设置 --> 更新与安全 --> Windows安全中心 --> 病毒和威胁防护 --></span> 请看第2步   <br>', 
        windows26: '第 2 步：进入 <span class="bold">病毒和威胁防护 --> 保护历史记录</span>，在检查出威胁栏目里，找到“绝尘加速器”，选择恢复即可。（由于不同的系统版本，设置方法略有差异，如需帮助请点击右侧客服图标）  <br>', 



        android10: '绝尘帮助中心',
        android11: '安卓端安装失败？',
        android12: '原因：因该类软件的行业特殊性，下载/安装时可能会遇到各种阻碍。',
        android13: '如下载遇到问题，请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
        android14: '手机品牌：',
        android15: '华为/荣耀',
        android16: '小米/红米',
        android17: 'VIVO',
        android18: 'OPPO',
        android19: '一加',
        android20: '第 1 步：打开手机 <span class="bold">设置 --> 安全</span> --> 请看第2步 <br>',
        android21: '第 2 步：打开 <span class="bold">更多安全设置 --> 安装外部来源应用</span> --> 找到当前使用的浏览器给予相关安装权限（如uc浏览器） <br>',
        android22: '第 1 步：打开手机 <span class="bold">设置 --> 隐私保护</span> --> 请看第2步 <br>',
        android23: '第 2 步：点击“<span class="bold">管理</span>” <br>',
        android24: '第 3 步：点击“<span class="bold">特殊应用权限</span>” <br>',
        android25: '第 4 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android26: '第 5 步：找到当前使用的浏览器给予相关安装权限（如Google浏览器） <br>',
        android27: '第 1 步：打开手机 <span class="bold">设置 --> 应用与权限</span> --> 请看第2步 <br>',
        android28: '第 2 步：点击“<span class="bold">权限</span>” <br>',
        android29: '第 3 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android30: '第 4 步：找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',
        android31: '第 1 步：打开手机 <span class="bold">设置 --> 安全</span> --> 请看第2步 <br>',
        android32: '第 2 步：点击“<span class="bold">安装外部来源应用</span>” <br>',
        android33: '第 3 步：点击“<span class="bold">安装外部来源应用</span>”找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',
        android34: '第 1 步：打开手机 <span class="bold">设置 --> 应用和通知</span> <br>',
        android35: '第 2 步：点击“<span class="bold">特殊应用权限</span>” <br>',
        android36: '第 3 步：点击“<span class="bold">安装未知应用</span>” <br>',
        android37: '第 4 步：找到当前使用的浏览器给予相关安装权限（如google浏览器） <br>',


        /*   <span class="bold"></span>  */
        ios10: '绝尘帮助中心',
        ios11: 'iOS/Mac下载教程',
        ios12: '原因：因该类软件的行业特殊性，大陆暂时不支持上架。',
        ios13: '下载请参考以下解决方法（也可以在12:00-23:59期间点击右侧的客服图标联系客服）：',
        ios14: '请选择符合您的情形',
        ios15: '我有境外的苹果ID',
        ios16: '我只有大陆的苹果ID',
        ios17: '第 1 步：打开手机 <span class="bold">App Store</span> <br>',
        ios18: '第 2 步：如当前登录的是大陆ID，请点击“<span class="bold">头像</span>”icon。如已登录境外ID，请直接看第5步。 <br>',
        ios19: '第 3 步：点击头像后进入“账户” <br>',
        ios20: '第 4 步：滑至底部，点击“<span class="bold">登出</span>”--> 然后登录您的境外ID就好 <br>',
        ios21: '第 5 步：点击按钮跳转商城，或直接在商城搜索“绝尘加速器“或者”JC VPN” <br>',
        // collapse titles 
        ios22: '创建专属的境外苹果ID',
        ios23: '推荐',
        ios24: '自己的专属ID更安全，更方便',
        ios25: '点击展开教程',// down
        ios25up: '点击收起教程',//up
        ios26: '修改你的苹果ID地区',
        ios27: '在已有的苹果ID修改地区即可安装',//
        ios28: '使用共享的境外苹果ID',
        ios29: '谨记不要在设置里登录，请在App Store登录',
        // 
        ios30: '第 1 步：前往苹果官网ID注册页 --> 填写“ <span class="bold">姓名、国家或地区、邮箱、密码、手机号码</span>”即可 <br>',
        ios31: '第 2 步：<span class="bold">验证邮箱/手机号 </span>-->验证成功后请看下一步 <br>',
        ios32: '第 3 步：<span class="bold">添加付款方式</span>（从美国地址生成器把内容复制粘贴就行，前往<span class="bold"><a href="https://www.meiguodizhi.com/" target="_blank">美国地址生成器</a></span>） <br>',
        ios33: '第 4 步：打开“App Store”  <br>',
        ios34: '第 5 步：如当前登录的是大陆ID，请点击“<span class="bold">头像</span>”icon（ 如未登录请直接登录刚注册的ID） <br>',
        ios35: '第 6 步：进入“账户”后，滑至底部，点击“<span class="bold">登出</span>”，然后登录刚注册的ID <br>',
        ios36: '第 7 步：登录成功后，搜索“<span class="bold">绝尘加速器”或者“JCVPN”</span>，下载即可畅游全球 <br>',

        ios37: '前往苹果官方教程',

 
        client_windows:"Windows",
        client_android:"Android",
        client_ios:"下载iOS端",
        client_mac:"macOS",
        client_linux:"Linux", 

        
       payment_success1:"充值绝尘币",
       payment_success2:"复制",
       payment_success3:"支付成功",
       payment_success4:"感谢您的购买！如遇问题，请联系客服~",
       payment_success5:"订单号",
       payment_success6:"已复制订单号",
       payment_success7:"订单号未复制",


        
        privacy1:  '绝尘加速器（以下简称绝尘）通过在连接到Internet时建立安全的虚拟专用网络，将服务作为保护您隐私的一种手段。<br>',
        privacy2:  '绝尘尊重并保护所有使用绝尘网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务,绝尘会按照本隐私政策的规定使用和披露您的个人信息，但绝尘将以高度的勤勉、审慎义务对待这些信息。<br>',
        privacy3:  '除本隐私权政策另有规定外,在未征得您事先许可的情况下,绝尘不会将这些信息对外披露或向第三方提供。绝尘会不时更新本隐私权政策。您在同意绝尘络服务使用协议之时,即视为您已经同意本隐私权政策全部内容。本隐私权政策属于绝尘网络服务使用协议不可分割的一部分。',
        privacy4:  '1. 用户数据收集',
        privacy5:  '我们确保永远不会记录用户的浏览历史记录，访问的数据内容，以及DNS查询。因此：',
        privacy6:  '我们不知道哪个用户曾经访问过特定的网站或服务。',
        privacy7:  '我们不知道哪个用户在特定时间连接了加速器以及使用了哪个加速器服务器的IP地址。',
        privacy8:  '我们不知道用户计算机或者移动设备的原始IP地址。',
        privacy9:  '如果有人需要我们提供上述用户信息，我们将无法提供，因为数据不存在。',
        privacy10: '手机号和邮箱',
        privacy11: '我们后期在用户注册时，可能会选择性让用户绑定手机号和邮箱（选填），我们收集用户绑定的手机号或者邮箱，用于帮助用户找回账号密码，以及在反馈问题后及时收到我们的回复。',
        privacy12: '设备型号和系统版本',
        privacy13: '我们收集用户的设备型号和系统版本，用来统计哪些型号的设备或者操作系统，连接VPN后不太好用，便于我们针对性的调试和解决问题。',
        privacy14: '2. 信息使用',
        privacy15: 'a) 绝尘不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息,除非事先得到您的许可',
        privacy16: 'b) 绝尘亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何绝尘平台用户如从事上述活动,一经发现,绝尘有权立即终止与该用户的服务协议。',
        privacy17: 'c) 为服务用户的目的,绝尘可能通过使用您的个人信息,向您提供您感兴趣的信息,包括但不限于通过邮件或者短信等向您发出产品和服务信息。',
        privacy18: '3. 信息披露',
        privacy19: '在如下情况下,绝尘将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息:',
        privacy20: 'a) 经您事先同意,向第三方披露;',
        privacy21: 'b) 为提供您所要求的产品和服务,而必须和第三方分享您的个人信息;',
        privacy22: 'c) 根据法律的有关规定,或者行政或司法机构的要求,向第三方或者行政、司法机构披露;',
        privacy23: 'd) 如您出现违反所在国家地区的有关法律、法规或者绝尘服务协议或相关规则的情况,需要向第三方披露;',
        privacy24: 'e) 其它绝尘根据法律、法规或者网站政策认为合适的披露。',
        privacy25: '4. 信息安全',
        privacy26: 'a) 绝尘帐号均有安全保护功能,请妥善保管您的用户名及密码信息。绝尘将通过对用户密码进行加密等安全措施确保您的信息不丢失,不被滥用和变造。尽管有前述安全措施,但同时也请您注意在信息网络上不存在“完善的安全措施”。',
        privacy27: 'b) 如您发现自己的个人信息泄密,尤其是绝尘用户名及密码发生泄露,请您立即联络绝尘客服,以便绝尘采取相应措施。',
        privacy28: '5.改变',
        privacy29: 'a)我们保留自行决定随时修改或替换这些条款的权利。在这些修订生效后继续访问或使用我们的服务，您同意受修订条款的约束。如果您不同意新条款，请停止使用本服务。',
        privacy30: '我们可能随时和不时更改我们的条款。最新版本的条款反映在本协议顶部的版本日期。所有更新和修订在更新后立即生效。我们建议您经常查看我们的条款，以便随时了解可能对您产生的影响，因为您继续使用该申请表示您继续同意接受我们的条款约束。',
        privacy31: 'b)我们可以出于任何原因立即终止或暂停访问我们的服务，恕不另行通知或承担任何责任，包括但不限于您违反本条款。本条款的所有条款本质上应在终止后继续有效，包括但不限于所有权条款，保证免责声明，赔偿和责任限制。',
        privacy32: '6.适用法律',
        privacy33: '这些条款应受所在国家地区的法律管辖和解释，而不考虑其法律冲突规定。',
        privacy34: '我们未能执行这些条款的任何权利或规定将不被视为放弃这些权利。如果这些条款的任何条款被法院认定为无效或不可执行，则这些条款的其余条款将继续有效。这些条款构成我们之间关于我们服务的完整协议，并取代我们之间可能拥有的有关服务的任何先前协议。',

        terms1:  '一般的',
        terms2:  '我们的目标是为所有客户提供优质服务。在您访问本网站或服务之前，请仔细阅读这些服务条款。',
        terms3:  '本服务条款（以下简称“本条款”）约束您对 绝尘加速器 及其相关网站域名（以下简称“网站”）和相关服务（以下简称“服务”）的使用，这些服务由 绝尘 团队（以下简称“绝尘”、“我们的”、“我们”）拥有和运营。本条款构成您与 绝尘 团队之间具有法律约束力的协议（以下简称“协议”）。',
        
        terms4:  '绝尘加速器 服务',
        terms5:  '您了解我们为您提供虚拟专用网络（或“加速器”）服务，包括但不限于使用服务器、传输、路由器、IP 地址和其他设备和协议通过我们的网络（“系统”）传输信息。您同意遵守有关您使用服务的条款。',
        
        terms6:  '使用政策',
        terms7:  '通过使用 绝尘加速器 服务（“服务”），您同意：',
        terms8:  'a) 不得使用该系统发送垃圾邮件、端口扫描、扫描开放代理或开放中继、发送选择加入电子邮件、未经请求的电子邮件或任何类型或版本大量发送的电子邮件，即使该电子邮件最终是从另一台服务器发送的。',
        terms9:  'b) 不要从我们的服务启动任何弹出窗口。',
        terms10: 'c) 在使用我们服务时不得以任何方式攻击任何其他计算机或网络。',
        terms11: 'd) 不得使用该服务进行可能损害我们的网络或其他人的事情。',
        terms12: 'e) 用户有责任了解并理解与您和您的行为有关的任何司法管辖区或地点的所有相关法律。绝尘加速器 不以任何方式或形式对其用户的行为负责，包括刑事责任和对造成或未造成的损害的民事责任，超出具体个人向 绝尘加速器 支付的任何服务费用。',
        terms13: 'f) 违反本使用政策或条款可能会导致您的帐户被终止，并且不会退还之前为服务支付的任何金额。此外，您可能要对 绝尘 遭受的任何和所有损失负责，包括由于上述违规行为而由任何外部实体收取的任何金额，包括但不限于律师费和费用。',
        terms14: 'g) 绝尘加速器 可让您将软件、软件更新或补丁或其他实用程序和工具下载到您的手机或支持互联网的设备上。绝尘加速器（“软件”）授予您非独占的有限许可，仅允许您根据本条款将软件用于 绝尘加速器 在向您提供软件时所述的目的。严禁修改、分发给未经授权的各方、进行逆向工程或以任何未经 绝尘加速器 明确授权的方式使用软件。',
        
        terms15: '免责声明',
        terms16: 'a) 我们努力防止网站和服务中断。但是，这些内容是按“原样”和“可用”提供的，并且我们不以明示或暗示的方式保证通过网站或服务提供的任何材料或信息的准确性，也不保证其适用于任何特定用途。',
        terms17: 'b) 实际服务覆盖范围、速度、位置和质量可能有所不同。除维护和维修的有限时间外，服务将尝试随时可用。但是，服务可能会因我们无法控制的各种因素而不可用，包括紧急情况、第三方服务故障、传输、设备或网络问题或限制、干扰、信号强度，并且可能会中断、拒绝、限制或缩减。',
        terms18: 'c) 我们不保证服务将满足您的要求，也不保证服务不会中断、及时、安全或无错误，也不保证缺陷（如有）将得到纠正。您承认，您访问网站和服务完全出于您的判断并由您自行承担风险。',
        terms19: 'd) 对于因服务或通信服务或网络（例如 T-1 线路或互联网）中断或性能问题而导致的数据、消息或页面丢失、未送达、延迟或误传，我们概不负责。我们可自行决定限制使用量或服务、暂停服务或阻止某些类型的使用，以保护用户或服务。网络速度是估算值，并不表示您或服务发送或接收数据的速度。实际网络速度将根据配置、压缩、网络拥塞和其他因素而有所不同。我们无法保证所接收数据的准确性和及时性；可能会出现延迟或遗漏。',
        terms20: 'e) 我们不记录任何用户活动（访问的网站、DNS 查询、电子邮件等）。我们仅记录对我们服务器的访问尝试，以用于故障排除和改进服务。我们不参与任何形式的审查。我们不会将您的个人信息提供给任何第三方。',
        
        terms21: '条款变更',
        terms22: 'a) 绝尘加速器 可能会不时更新本条款，恕不另行通知。本条款的任何变更都将纳入修订后的协议，我们将在网站上发布该协议。除非另有规定，否则此类变更自发布之日起生效。如果我们对这些条款做出重大变更，我们将通过应用程序或在您查看我们的网站时通知您。',
        
        terms23: '最后',
        terms24: 'a) 绝尘加速器 深信消费者隐私、安全和网络自由，我们以此管理和保护消费者隐私和个人信息。如果您有任何问题或疑问，我们都会通过电子邮件地址<br>juechenvpn@gmail.com为您提供支持',
        

 

        home: 'Home',
        support:'Support',
        contactus:'Contact us',
        settings: 'Settings',
        change_locale: 'Change Language',
        dashboard: 'Dashboard',
        login_username: 'Username',
        login_password: 'Password',
        submit: 'Submit',
        logout: 'Logout', 
        add_new_user: 'Add new user',
        username: 'Username',
        password: 'Password', 
        email: 'Email',
        phone: 'Phone',
        activate: 'Activate',
        validity: 'Validity',
        active: 'Active',
        inactive: 'Inactive', 
        awaiting: 'Awaiting', 
        traffic_done: 'Traffic Done',
        status: 'Status', 
        display: 'Display',  
        permission: 'Permission',
        date_created: 'Date Created',
        date_update: 'Date Updated',
        action: 'Action',
        increase: 'Increase',
        decrease: 'Decrease',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        go_back: 'Go Back',
        select: 'Select',  
        name: "Name",
        key: "Key", 
        bandwidth: 'Bandwidth',
        extra_bandwidth: 'Extra Bandwidth',
        bandwidth_high: 'Bandwidth High limit(GB)',
        bandwidth_low: 'Bandwidth Low limit(GB)', 
        bhigh: 'High',
        blow: 'Low', 
        confirm_delete: 'Are you sure to perform this action?', 
        save:'Save',
        billed: 'Billed',
        not_listed: 'Not Listed',
        sorting_order: 'Sorting order',
        description: 'Description',
        monthly: 'Monthly',
        weekly: 'Weekly',
        quarterly: 'Quarterly',
        biannual: 'Biannual',
        yearly: 'Yearly',
        manually: 'Manually',
        title: 'Title',
        for: "for",
        all: "All", 
        android: "Android", 
        ios: "IOS",
        languages: "Languages",
        price: 'Price',
        coins: 'Coins',
        extra_coins: 'Extra Coins',
        is_basic_plan: 'Basic Plan',
        discount: 'Discount',
        rate:'Rate',
        currencies: "currencies",
        detail: 'Detail',
        speed_limit: 'Speed limit',
        manage: 'Manage',
        payment: 'Payment',
        methods: 'Methods',
        mode: 'Mode',
        live: 'Live',
        test: 'Test',
        data: 'Data',
        icon: 'Icon',
        code: 'Code',
        channels: 'Channels',
        groups: 'Groups',
        group: 'Group',
        remark: 'Remark',
        info: 'Info',
        assign: 'Assign',
        to: 'to',
        method: 'method',
        shortcuts: 'Shortcuts',
        link: 'Link',
        view: 'View',
        list: 'List',
        users: 'Users',
        user: 'User',
        search: 'Search',
        servers: 'Servers',
        areas: 'Areas',
        types: 'Types',
        lists: 'Lists',
        information:'Information',
        main: 'Main',
        multiple:'Multiple',
        ip: 'IP',
        port: 'Port',
        ports: 'Ports',
        total: 'Total',
        clients: 'Clients',
        none: 'Not Selected',
        announcements: 'Announcements',
        created: 'Created',
        expired: 'Expired',
        at: 'at',
        url: 'Url',
        yes: 'Yes',
        no: 'No',
        base_currency: 'Base Currency',
        config: 'Config',
        configuration: 'Configuration',
        order: 'Order',
        orders: 'Orders',
        number: 'number',
        amount: 'Amount',
        package: 'Package',
        paid: 'Paid',
        un_paid: 'Unpaid',
        started: 'Started',
        completed: 'Completed',
        pending: 'Pending',
        cancelled: 'Cancelled',
        rejected: 'Rejected',
        refund: 'Refund',
        default: 'Default',
        allowed: 'Allowed',
        devices: 'Devices',
        additonal: 'Additonal',
        version: 'Version',
        version_old: 'Version old',
        sha256: 'sha256',
        pack_size: 'Pack size',
        force: 'Force',
        type: 'Type',
        force_type_zero: 'User chooses to update',
        force_type_one: 'Force to update all',
        currency: 'Currency', 
        apply: 'Apply', 
        refresh: 'Refresh',
        reset: 'Reset',
        close: 'Close',
        plan_time: 'Plan time',
        minutes: 'Minutes',
        days:"Days",
        packages: 'Packages',
        start: 'Start',
        end: 'End',
        date: 'Date',
        free: 'Free',
        premium: 'Premium',
        both: 'Both',
        transfer: 'Transfer',
        id: 'Id',
        url_config: 'Config',
        value: 'Value',
        extra: 'Extra',

        online: 'Online',
        today: 'Today',
        new: 'New',
        not: 'Not',
        confirmed: 'Confirmed',
        trial: 'Trial',
        reward: 'Reward',
        login: 'Login',
        level: 'Level',
        language: 'Language',
        invite: 'Invite',
        referrer: 'Referrer',
        faq: 'FAQ',
        question: 'Question',
        answer: 'Answer',
        blog: 'Blog',
        slug: 'Slug',
        viewed: 'Viewed',
        image: 'Image',
        meta: 'Meta',
        text: 'Text',
        optional: 'Optional',
        keywords: 'keywords',
        welcome: 'Welcome',
        console: 'Console',
        author: 'Author',
        category: 'Category', 
        categories: 'Categories',
        months: 'Months',
        recommended: 'Recommended',
        news: 'News',
        ticket: 'Tickets',
        subject: 'Subject',
        resolved: 'Resolved',
        replied: 'Replied',
        spam: 'Spam',
        reply: 'Reply',
        message: 'Message', 
        messages: 'Messages',
        admin:'Admin',
        admin_operation_logs: 'Admin Operation Logs',
        restricted: 'Restricted',
        area: 'Area',
        reports: 'Reports',
        registered: 'Registered',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        last_90_days: 'Last 90 days',
        last_365_days: 'Last 1 year',
        last_2_years_days: 'Last 2 Years',
        last_3_years_days: 'Last 3 Years', 
        days_count:' Days Count',
        _1_days: ' 1 Days',
        _3_days: ' 3 Days',
        _7_days: '7 Days',
        _30_days: '30 Days',
        _90_days: '90 Days',
        _180_days: '180 Days',
        _1_years_days: '1 year (360 Days) ',
        _2_years_days: '2 Years (730 Days)',
        _3_years_days: '3 Years (1095 Days)',
        client: 'Client',
        tutorial: 'Tutorial',
        paying: 'Paying',
        other: 'other',
        yesterday: 'Yesterday',
        custom_date_filter: 'Custom date',
        vpn: 'VPN',
        lattitude: 'lattitude',
        longitude: 'longitude',
        max_network_bandwidth: 'Max network bandwidth',
        network_bandwidth: 'Network bandwidth',
        load: 'load',
        mid: 'MID',
        hourly: 'Hourly',
        udid: 'udid',
        token: 'Token',
        first: 'First',
        time: 'Time',
        bugs: 'Bugs',
        user_id: 'User ID',
        node_id: 'Node',
        log_detail: 'Log Detail',
        device_detail: 'Device Detail',
        client_id: 'Client_ID',
        unique_no: 'Unique No',
        clients_error: 'Errors',
        renewals: 'Renewals',
        download: 'download',
        last_active_time: 'Last active time',
        temp: 'Temp',
        offer: 'offer',
        push_notification: 'Push Notification',
        send: 'Send',
        sent: 'Sent',
        not_sent: 'Not Sent',
        device: 'Device',
        whitelist: 'Whitelist',
        points: 'Points',
        total_points: 'Total Points',
        deep_link: 'Deep Link',
        is_offer: 'Is Offer',
        is_disabled: 'Is Disabled',
        gift_code_builder: 'Gift Code Builder',
        batch: 'Batch',
        count: 'Count',
        purpose: 'Purpose',
        plan: 'Plan', 
        plans: 'Plans',
        generated_by: 'Generated By',
        receive_money: 'Receive Money',
        finance_comment: 'Finance comment',
        gift_codes: 'Gift Codes',
        cancel: 'Cancel',
        generate: 'Generate',
        btach_name: 'Batch Name',
        sr_no: 'SR.NO',
        card_code: 'Card-Code',
        redeem_status: 'Redeem Status',
        used_time: 'Used Time', 
        used: 'Used',
        demo: 'Preview', 
        refer_friend:"Refer Friend",
        verify_email: "Verify Email", 
        by: "by",  
        last: "Last", 
        preffered_language: "Preffered language",
        preffered_theme: "Preffered theme", 
        verification_code: "Verification Code",
        refferal_id: "Refferal ID",
        order_history: "Order history",
        packages_history: "Packages history",
        order_not_find:"No Orders found",
        packages_not_find:"No packages found",
        earn_points_not_found:"No Earn points found",
        redeem_points_not_found: "No Redeem Points found",
        devices_not_found: "Devices not found",
        tickets_not_found: "Tickets not found",
        lookup: "Lookup",
        transaction_id: "Transaction ID",
        check: "Check",
        allowed_countries: "Allowed Countries",
        guest: "Guest",
        redeem: "Redeem",
        account: "Account",
        current: "Current",
        change_password_heading: "Please change your password",
        country: 'Country', 
        domains: 'Domains',
        domain: 'Domain',
        weight: 'Weight',  
        protocol:'Protocol',
        available:"Available",
        order_number_not_found:"Order not found with this order number.",
        order_username_different:"Order username not matched with given username.",
        package_not_found:"Package not found.",
        balance:"Balance",
        in:"In",
        out:"Out",
        deleted:"Deleted",

        



        windows_doc:"Windows下载失败？",
    }  

}
